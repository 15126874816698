<template>
  <div>
    <div class="operations">
      <a-input-search v-model:value="keywordValue" placeholder="输入关键字搜索" enter-button @search="onSearch" style="width: 300px; outline: none" />
      <div style="flex: 1"></div>
      <a-button :disabled="!selectedRowKeys.length" type="primary" style="margin-right: 20px" @click="downloads">
        <template #icon>
          <download-outlined />
        </template>
        下载
      </a-button>
      <a-button @click="showForm = true">添加</a-button>
    </div>
    <giga-table
      :gData="dataSource"
      :gColumns="columns"
      :rowSelection="{ selectedRowKeys, onChange: onSelectChange }"
      :pagination="{ pageSize, current: pageNum, total, onChange: onPageChange }"
      :loading="tableLoading"
      :scrollX="1300"
    />
    <CasebookForm
      :visible="showForm"
      :gigaShop="gigaShop"
      :gigaServiceManagers="gigaServiceManagers"
      :vehicles="vehicles"
      :products="products"
      :formData="formState"
      :vehicleFilms="vehicleFilms"
      @formCancel="handleCancel"
      @formOk="handleOk"
    />
  </div>
</template>
<script>
import { DownloadOutlined } from '@ant-design/icons-vue';
import { message } from 'ant-design-vue';
import { getCasebooksTable, editCasebook, getProducts, getVehicles, getChoice, getVehicleFilms } from '../http/api';
import GigaTable from '../components/GigaTable.vue';
import CasebookForm from '../components/form/CasebookForm';

export default {
  components: {
    DownloadOutlined,
    GigaTable,
    CasebookForm,
  },
  data() {
    return {
      tableLoading: false,
      selectedRowKeys: [],
      keywordValue: '',
      showForm: false,
      addItem: false,
      formState: {},
      dataSource: [],
      total: 0,
      pageSize: 10,
      pageNum: 1,
      vehicles: [],
      products: [],
      gigaShop: [],
      gigaServiceManagers: [],
      columns: [
        {
          title: 'id',
          dataIndex: 'id',
          key: 'id',
          width: 80,
        },
        {
          title: '案例标题',
          dataIndex: 'title',
          key: 'title',
          width: 300,
        },
        {
          title: '所属产品',
          dataIndex: 'product',
          key: 'product',
          customRender: ({ record }) => {
            let list = record.products.length ? record.products : [record.product];
            list = list.filter(Boolean);
            return list.length > 0 ? (
              <div>
                {list.map((i) => (
                  <div>{i.name}</div>
                ))}
              </div>
            ) : null;
          },
        },
        {
          title: '封面图片',
          dataIndex: 'coverImage',
          key: 'coverImage',
          width: 100,
          customRender: (val) => {
            return <img src={(val.value && val.value.url) || ''} style="width: 40px; max-height: 88px" />;
          },
        },
        {
          title: '是否超级车间施工',
          dataIndex: 'constructedFromGiga',
          key: 'constructedFromGiga',
          width: 150,
          customRender: (val) => <span v-html={val.value ? '是' : '否'} />,
        },
        {
          title: '施工店铺',
          dataIndex: 'shop',
          key: 'shop',
          width: 100,
        },
        {
          title: '服务经理',
          dataIndex: 'uploader',
          key: 'uploader',
          width: 100,
        },
        {
          title: '是否发布',
          dataIndex: 'isPublished',
          key: 'isPublished',
          fixed: 'right',
          width: 100,
          customRender: (val) => <a-badge status={val.value ? 'success' : 'warning'} text={val.value ? '已发布' : '未发布'} />,
        },
        {
          title: '操作',
          dataIndex: 'operation',
          key: 'operation',
          fixed: 'right',
          width: 120,
          customRender: (val) => {
            return (
              <div>
                <a-dropdown
                  trigger={['click']}
                  overlay={
                    <a-menu>
                      <a-menu-item onClick={() => this.editCasebook(val.record)}>
                        <span>编辑</span>
                      </a-menu-item>
                      <a-menu-item onClick={() => this.editCasebook(val.record, 'publish')}>
                        <span v-html={val.record.isPublished ? '取消发布' : '发布'}></span>
                      </a-menu-item>
                      <a-menu-item onClick={() => this.editCasebook(val.record, 'del')}>
                        <span>删除</span>
                      </a-menu-item>
                    </a-menu>
                  }
                >
                  <a-button>操作</a-button>
                </a-dropdown>
              </div>
            );
          },
        },
      ],
    };
  },
  created() {
    this.getCasebooks();
    this.getData();
  },
  methods: {
    downloads() {
      console.log(window);
      const url = `https://giga-api.xiaote.net/api/v1/download_case_book_images?oids=${this.selectedRowKeys.join(',')}`;
      window.open(url, 'target');
      // const a = document.createElement('a');
      // a.href = url;
      // a.download = true;
      // a.click();
    },
    onSelectChange(selectedRowKeys) {
      if (selectedRowKeys.length > 20) {
        message.warning('一次最多下载20个！');
        selectedRowKeys.length = 20;
      }
      this.selectedRowKeys = selectedRowKeys;
    },
    setImg(e, type) {
      let img = e[0];
      let formState = this.formState;
      formState[type] = img.url;
      this.formState = JSON.parse(JSON.stringify(formState));
    },
    handleOk(formState) {
      editCasebook({ variables: formState })
        .then((res) => {
          console.log(res);
          this.handleCancel();
          this.getCasebooks();
        })
        .catch((err) => {
          console.log(err);
        });
    },

    editCasebook(record, type) {
      if (type == 'del') {
        this.handleOk({ id: record.id, isDeleted: true });
      } else if (type == 'publish') {
        this.handleOk({ id: record.id, isPublished: !record.isPublished });
      } else {
        console.log(record);
        record.images = record.photos;
        record.vehicleId = record.vehicle.id;

        let products = record.products.length ? record.products : [record.product];
        products = products.filter(Boolean);
        if (products.length > 0) {
          record.productIds = products.map((i) => i.id);
        }
        delete record.product;

        this.formState = record;
        this.showForm = true;
      }
    },

    handleCancel() {
      this.showForm = false;
      this.addItem = false;
      this.formState = {};
    },

    onSearch() {
      this.pageNum = 1;
      this.getCasebooks();
    },

    onPageChange(page, size) {
      this.pageNum = page;
      this.pageSize = size;
      this.getCasebooks();
    },

    async getCasebooks() {
      this.tableLoading = true;
      const res = await getCasebooksTable({
        variables: { keyword: this.keywordValue || null, paginate: { pageSize: this.pageSize, page: this.pageNum } },
      });
      this.tableLoading = false;
      if (res.errors) return this.$message.error(res.errors[0].message);
      this.dataSource = res.data.casebooksForAdmin.data;
      this.total = res.data.casebooksForAdmin.pageInfo.total;
    },

    getData() {
      Promise.all([getProducts({ variables: { limit: 99 } }), getVehicles(), getChoice(), getVehicleFilms()]).then((res) => {
        console.log(res);
        let products = res[0].data.products;
        let vehicles = res[1].data.vehicles;
        let gigaShop = res[2].data.choice.gigaShop;
        let gigaServiceManagers = res[2].data.choice.gigaServiceManagers;
        let vehicleFilms = res[3].data.vehicleFilms;
        this.vehicles = vehicles;
        this.products = products;
        this.gigaShop = gigaShop;
        this.gigaServiceManagers = this.uniqueJsonArrByField(gigaServiceManagers, 'value');
        this.vehicleFilms = vehicleFilms;
      });
    },
    uniqueJsonArrByField(jsonArr, field) {
      if (jsonArr.length < 2 || !field || typeof jsonArr[0] !== 'object') return jsonArr;
      const res = new Map();
      const uniqueArr = jsonArr.filter((item) => !res.has(item[field]) && res.set(item[field], true));
      return uniqueArr;
    },
  },
};
</script>
<style lang="scss" scoped></style>
